@import url('https://fonts.googleapis.com/css2?family=League+Gothic:wdth@75..100&display=swap') body;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background-color: #002045;
}

body {
    font-family: "League Gothic", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body {
    width: 100%;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    display: none;
}

.slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}

.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 18px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '▬';
    text-align: center;

    transition-property: color;
    transition-duration: 0.2s;

    opacity: 0.8;
    color: #ffffff;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #e3003a;
}
